import React, {useEffect, useState} from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Table,
} from "reactstrap";
import { api, getLocaleFixedValue, SOCKET, socket, PAYMENT_STATUS, USER_ROLES } from "../../util";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/common/Loading";

const SaloonDashboard = () => {
	const user = useSelector((state) => state.user);
    const [totalYearlySales , setTotalYearlySales] = useState("0.00");
    const [totalMonthlySales , setTotalMonthlySales] = useState("0.00");
    const [totalYearlyTarget , setTotalYearlyTarget] = useState("0.00");
    const [totalMonthlyTarget , setTotalMonthlyTarget] = useState("0.00");
    const [employeeTicketAverage , setEmployeeTicketAverage] = useState([]);
    const [isLoading , setIsLoading] = useState(false);

    const getDashboardDetails = async () => {
        try {
            setIsLoading(true);
            const response = await api.get('/dashboard/getSaloonDashBoard');
            if(response.status === 200) {
                if(response?.data?.data) {
                    var responseData = response.data.data;
                    setTotalMonthlySales(responseData?.totalMonthlySales?.totalAmount ? getLocaleFixedValue(responseData.totalMonthlySales.totalAmount) : "0.00");
                    setTotalMonthlyTarget(responseData?.totalMonthlyTarget?.targetValue ? getLocaleFixedValue(responseData.totalMonthlyTarget.targetValue) : "0.00");
                    setTotalYearlySales(responseData?.totalSales?.totalAmount ? getLocaleFixedValue(responseData.totalSales.totalAmount) : "0.00");
                    setTotalYearlyTarget(responseData?.totalTarget?.targetValue ? getLocaleFixedValue(responseData.totalTarget.targetValue) : "0.00");
                    setEmployeeTicketAverage(responseData?.ticketAverage);
                }
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getDashboardDetails();
    } , []);
    
    useEffect(() => {
    	console.log("getEmployeeWeekAverage");
        getEmployeeWeekAverage();
    } , []);
    const [averageEmpNames , setAverageEmpNames] = useState([]);
    const [weeklyAvgDetails , setWeeklyAvgDetails] = useState([]);
    const getEmployeeWeekAverage = async () => {
        try {
            const response = await api.get('/dashboard/getEmployeeWeekAverage');
            console.log("response" , response );
            if(response.status === 200) {
                if(response?.data?.data) {
                	var responseData = response.data.data;
                	setWeeklyAvgDetails(responseData?.results);
                	setAverageEmpNames(responseData?.employees);
                }
            }
            /*if(response.status === 200) {
                if(response?.data?.data) {
                    var responseData = response.data.data;
                    setTotalMonthlySales(responseData?.totalMonthlySales?.totalAmount ? responseData.totalMonthlySales.totalAmount : "0.00");
                    setTotalMonthlyTarget(responseData?.totalMonthlyTarget?.targetValue ? responseData.totalMonthlyTarget.targetValue : "0.00");
                    setTotalYearlySales(responseData?.totalSales?.totalAmount ? responseData.totalSales.totalAmount : "0.00");
                    setTotalYearlyTarget(responseData?.totalTarget?.targetValue ? responseData.totalTarget.targetValue : "0.00");
                    setEmployeeTicketAverage(responseData?.ticketAverage);
                }
            }*/
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
    }
    const weekDays = [
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
          'Sunday'
    ];
    useEffect(() => {
        if (!socket) return;
          socket.emit(SOCKET.EMIT_JOIN, {
            roomId: "SUPER_ADMIN",
            from: {
              id : user?.id,
              accountId : user?.accountId,
              role : (user?.accountId && user.accountId > 0 ? USER_ROLES.ACCOUNT : USER_ROLES.SUPER_ADMIN)
            }
          });

        socket.on("disconnected", (data) => {
          
        });    

        return () => {
          socket.off(SOCKET.EMIT_JOIN);
        };
    }, []);

    useEffect(() => {
    	if (!socket) return;

        socket.on(SOCKET.RECEIVE_BILLING_DATA_CHANGE , (data) => {
        
	        if(data.paymentStatus === PAYMENT_STATUS.SUCCESSFUL) {
	        	getEmployeeWeekAverage();
	        }
	    })
	    return () => {
	    	socket.off(SOCKET.RECEIVE_BILLING_DATA_CHANGE);
	    };
    } , [socket]);
    return (
        <React.Fragment>
            <section>
            <Row className="gy-3">
	            <Col lg={12}>
	                <h4 className="text-white mb-3">Employee Weekly Average</h4>
	                <Card className="newdashboard-table weekly-avg-table">
	                    <CardBody className="px-2 py-0">
	                        <Table responsive>
	                        <thead>
	                            <tr>
	                                <th style={{minWidth: '110px',width: '110px'}}></th>
	                                <th className="text-center" style={{minWidth: '180px' ,width: '120px'}}>Employee</th>
	                                { 
	                                	weekDays.map((weekDay , index) => {
	                                		return (<th className="text-center" style={{minWidth: '120px' ,width: '120px'}}>{weekDay}</th>)
	                                	})
                                    }
	                            </tr>
	                        </thead>
	                        <tbody>
	                        	{averageEmpNames?.length > 0 ? (
	                        			averageEmpNames.map((averageEmpName , index) => (
                                        <>
                                            <tr>
                                                <td><span className="fw-bolder text-break">{index + 1}</span></td>
                                                <td className="text-center">{averageEmpName?.empName}</td>
                                                { 
            	                                	weekDays.map((weekDay , index) => {
            	                                		const weekName = weekDay;
            	                                		const empId = averageEmpName?.Employee?.id;
            	                                		const checkEmpWeekRecord = weeklyAvgDetails.find(weeklyAvgDetail => 
            	                                			weeklyAvgDetail.employeeId === empId && weeklyAvgDetail.weekDayName === weekName
            	                                		);
            	                                		var dayAverage = 0;
            	                                		if( checkEmpWeekRecord ){
            	                                			const daySales = checkEmpWeekRecord?.totalSales;
            	                                			const dayCount = checkEmpWeekRecord?.bookingCount;
            	                                			if( daySales && daySales ){
            	                                				dayAverage = ( parseFloat(daySales) / parseFloat(dayCount));
            	                                				dayAverage = ( parseFloat(dayAverage) > 0.00 ? parseFloat(dayAverage).toFixed(0) : 0 );
            	                                			}
            	                                		}
            	                                		return (<th className="text-center" style={{minWidth: '120px' ,width: '120px'}}>{dayAverage}</th>)
            	                                	})
                                                }
                                            </tr>
                                        </>
                                    ))
                                ) : (
                                    <tr>
                                      <td className="text-center" colSpan="9">
                                        <i class="bi bi-exclamation-circle"></i> No Data Found
                                      </td>
                                    </tr>
                                )}
	                        </tbody>
	                        </Table>
	                        {isLoading && (
	                            <Loading />
	                        )}
	                    </CardBody>
	                </Card>
	            </Col>
	            </Row>
                <Row className="gy-3">
                    <Col lg={6}>
                        <h4 className="text-white mb-3">Individual</h4>
                        <Card className="newdashboard-table individual-table">
                            <CardBody className="px-2 py-0">
                                <Table responsive>
                                <thead>
                                    <tr>
                                        <th style={{minWidth: '110px',width: '110px'}}></th>
                                        <th className="text-center" style={{minWidth: '180px' ,width: '180px'}}>Employee</th>
                                        <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Ticket Average</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeTicketAverage?.length > 0 ? (
                                        employeeTicketAverage.map((ticketAverageInfo , index) => (
                                            <>
                                                <tr>
                                                    <td><span className="fw-bolder text-break">{index + 1}</span></td>
                                                    <td className="text-center">{ticketAverageInfo?.Employee?.firstName + ' ' + ticketAverageInfo?.Employee?.lastName}</td>
                                                    <td className="text-center">{ticketAverageInfo?.ticketAverage ? getLocaleFixedValue(ticketAverageInfo.ticketAverage) : ''}</td>
                                                </tr>
                                            </>
                                        ))
                                    ) : (
                                        <tr>
                                          <td className="text-center" colSpan="10">
                                            <i class="bi bi-exclamation-circle"></i> No Data Found
                                          </td>
                                        </tr>
                                    )}
                                </tbody>
                                </Table>
                                {isLoading && (
                                    <Loading />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <h4 className="text-white mb-3">Store Statistics</h4>
                        <Row>
                            <Col size={12}>
                                <Card>
                                    <CardBody>
                                        <Row className="gy-3">
                                        <Col lg={6} className="border-end">
                                            <div className="d-flex flex-column align-items-center">
                                                <h5>Yearly Target</h5>
                                                <h4 className="mb-0 fw-bolder">${totalYearlyTarget}</h4>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="d-flex flex-column align-items-center">
                                                <h5>Total Sales</h5>
                                                <h4 className="mb-0 fw-bolder">${totalYearlySales}</h4>
                                            </div>
                                        </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col size={12}>
                                <Card>
                                    <CardBody>
                                        <Row className="gy-3">
                                        <Col lg={6}>
                                            <div className="border p-2 d-flex flex-column align-items-center rounded">
                                                <h5>Monthly Target</h5>
                                                <h4 className="mb-0 fw-bolder">${totalMonthlyTarget}</h4>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="border p-2 d-flex flex-column align-items-center rounded">
                                                <h5>Total Sales</h5>
                                                <h4 className="mb-0 fw-bolder">${totalMonthlySales}</h4>
                                            </div>
                                        </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </section>
        </React.Fragment>
  );
};

export default SaloonDashboard;
