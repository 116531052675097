import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Navbar,
  Collapse,
  Nav,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Button,
  Card, 
  CardBody, 
  CardTitle, 
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table
} from "reactstrap";
import user1 from "../assets/images/users/user4.jpg";
import SocketAdminStatus from "../components/common/SocketAdminStatus";
import SocketCustomerStatus from "../components/common/SocketCustomerStatus";
import { userLogout , employeeLogout } from "../redux/actions";
import { getDefaultRoute, USER_TYPES,
	api,
	socket,
	SOCKET,
	SHOW_CLOCK_IN_OUT_PERMISSION_ID,
	SHOW_NO_SALES_PERMISSION_ID,
	USER_ROLES,
	DB_FORMAT,
	STATUS,
	WORKING_LOG_EVENT,
	LOCAL_STORAGE_USER as LOCAL_STORAGE_USER_KEY,
} from "../util";
import ConfirmModal from "../components/common/ConfirmModal";
import { toast } from "react-toastify";


const Header = ({ isAuthenticated = true, hideMobileMenu }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { role, id } = useSelector((state) => state.user);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [admindropdownOpen, setadminDropdownOpen] = React.useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const admintoggle = () => setadminDropdownOpen((prevState) => !prevState);
  const Handletoggle = () => {
    setIsOpen(!isOpen);
  };
  const { permissions } = useSelector((state) => state.user);
  const showMobilemenu = () => {
    document.getElementById("sidebarArea").classList.toggle("showSidebar");
  };
  console.log("permissions" , permissions );
  const [empHaveClockInOutPerm, setmpHaveClockInOutPerm] = useState(false);
  const [empHaveNoSalesPerm, setEmpHaveNoSalesPerm] = useState(false);
  
  const [empHaveNoSalesStatus, setEmpHaveNoSalesStatus] = useState(null);
  const [empHaveClockInOutStatus, setEmpHaveClockInOutStatus] = useState(null);
  var checkEmpHaveClockInOutPermission = false;
  var checkEmpHaveNoSalesPermission = false;
  if( permissions ) {
	  checkEmpHaveClockInOutPermission = permissions.find(permissions => 
		(permissions.id === SHOW_CLOCK_IN_OUT_PERMISSION_ID) 
	  );
	  
	  checkEmpHaveNoSalesPermission = permissions.find(permissions => 
		(permissions.id === SHOW_NO_SALES_PERMISSION_ID) 
	  );
	  console.log("checkEmpHaveClockInOutPermission" , checkEmpHaveClockInOutPermission );
  }
  
  useEffect(() => {
    const adjustHeader = (e) => {
      if (
        typeof window.innerWidth === "number" &&
        window.innerWidth < 768 &&
        role === USER_TYPES.ADMIN
      ) {
        setIsOpen(true);
      }
    };
    adjustHeader()
    window.addEventListener("resize", adjustHeader);
    window.addEventListener("load", adjustHeader);

    return () => {
      window.removeEventListener("resize", adjustHeader);
      window.removeEventListener("load", adjustHeader);
    };
  }, []);
  
  const updateEmpPermission = async(record_name) => {
	  console.log("updateEmpPermission");
	  var permission_id = '';
	  var permission_status = '';
	  if( record_name === "clock_in_out_popup"){
		  if( checkEmpHaveClockInOutPermission ){
			  permission_id = SHOW_CLOCK_IN_OUT_PERMISSION_ID;
			  permission_status = false;
		  } else {
			  permission_id = SHOW_CLOCK_IN_OUT_PERMISSION_ID;
			  permission_status = true;
		  }
	  }
	  if( record_name === "no_sales_popup"){
		  if( checkEmpHaveNoSalesPermission ){
			  permission_id = SHOW_NO_SALES_PERMISSION_ID;
			  permission_status = false;
		  } else {
			  permission_id = SHOW_NO_SALES_PERMISSION_ID;
			  permission_status = true;
		  }
	  }
	  var updatePermissionObject = { permissionId  : permission_id , permissionStatus : permission_status , id: id }
	  
	  
	  try {
	      // setIsLoading(true);
	      const response = await api.post("/users/updatePermission", updatePermissionObject);
	      if(response.status === 200) {
	    	  var currentLoggedUserInfo = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
	    	  var currentLoggedUserInfoJson = JSON.parse(currentLoggedUserInfo);
	    	  currentLoggedUserInfoJson.permissions = response?.data?.data?.permissions
	    	  localStorage.setItem(LOCAL_STORAGE_USER_KEY,JSON.stringify(currentLoggedUserInfoJson));
	    	  window.location.reload();
	      }
	  } catch (error) {
	      console.log(error);
	      toast.error(error.response?.data?.message || error.message);
	  } finally {
		  //setIsLoading(false);
	  }
  }
  const [showPermissionConfirmPopup, setShowPermissionConfirmPopup] = useState(false);
  const [showNoSalesPermissionConfirmPopup, setShowNoSalesPermissionConfirmPopup] = useState(false);
  return (
    <Navbar
      expand="md"
      onClick={(e) => {
        hideMobileMenu();
      }}
      className="border-bottom fix-header"
    >
      <div className="d-flex align-items-center">
        <div className="d-lg-block d-none me-5 pe-3">
          <Link to={getDefaultRoute(role)} className="text-decoration-none text-dark">
            <h4 className="mb-0">Salon</h4>
          </Link>
        </div>
        <Link to={getDefaultRoute(role)} className="text-decoration-none text-dark d-lg-none">
          <h5 className="mb-0 me-2">Salon</h5>
        </Link>
        
        {isAuthenticated && (
          <Button
            color="primary"
            className=" d-lg-none"
            onClick={(e) => {
              e.stopPropagation();
              showMobilemenu();
            }}
          >
            <i className="bi bi-list"></i>
          </Button>
        )}
      </div>
      {isAuthenticated && (
        <div className="hstack gap-2">
          {role !== USER_TYPES.SUPER_ADMIN && (
            <Button color="primary" size="sm" className="d-sm-block d-md-none" onClick={Handletoggle}>
              {isOpen ? (
                <i className="bi bi-x"></i>
              ) : (
                <i className="bi bi-three-dots-vertical"></i>
              )}
            </Button>
          )}
          {id && [USER_TYPES.ADMIN, USER_TYPES.SUPER_ADMIN].includes(role) && (
            <Dropdown
              isOpen={admindropdownOpen}
              toggle={admintoggle}
              className="d-block d-md-none"
            >
              <DropdownToggle color="transparent">
                <img
                  src={user1}
                  alt="profile"
                  className="rounded-circle"
                  width="30"
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={userLogout}>Logout</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      )}

      <Collapse navbar isOpen={isOpen}>
        <Nav className="me-auto" navbar></Nav>
        {id && [USER_TYPES.SUPER_ADMIN].includes(role) && (
        <div className="d-flex gap-2 status_wrapper">
	        <div className="form-check form-switch">
	          <input type="checkbox" className="form-check-input" 
	        	  onChange={() => {
	        		  setEmpHaveClockInOutStatus( checkEmpHaveClockInOutPermission ? STATUS.ACTIVE_TEXT : STATUS.INACTIVE_TEXT);
	        		  setShowPermissionConfirmPopup(true)
                  }}
	        	  checked={ checkEmpHaveClockInOutPermission ? true : false }/>
	          <label className="form-check-label" htmlFor={"customSwitches-manual-booking-clock-in-out-notification"}>Clock In-Out Notification</label>
	        </div>
	        <div className="form-check form-switch">
	          <input type="checkbox" className="form-check-input"
	        	  onChange={() => {
	        		  setEmpHaveNoSalesStatus( checkEmpHaveNoSalesPermission ? STATUS.ACTIVE_TEXT : STATUS.INACTIVE_TEXT);
	        		  setShowNoSalesPermissionConfirmPopup(true)
                  }}
	        	  checked={ checkEmpHaveNoSalesPermission ? true : false }/>
	        	<label className="form-check-label" htmlFor={"customSwitches-manual-booking-no-sales-notification"}>No Sales Notification</label>
	        </div>
	    </div>
	    )}
        {showPermissionConfirmPopup && (
    		    <ConfirmModal
    	          isOpen={showPermissionConfirmPopup}
    	          setIsOpen={setShowPermissionConfirmPopup}
    	          onOk={() => updateEmpPermission('clock_in_out_popup')}
    	          onCancel={() => {
    	            setShowPermissionConfirmPopup(false);
    	          }}
    	          type="update"
    	          title="Update Permission"
    	        />
    	)}
        {showNoSalesPermissionConfirmPopup && (
    		    <ConfirmModal
    	          isOpen={showNoSalesPermissionConfirmPopup}
    	          setIsOpen={setShowNoSalesPermissionConfirmPopup}
    	          onOk={() => updateEmpPermission('no_sales_popup')}
    	          onCancel={() => {
    	        	  setShowNoSalesPermissionConfirmPopup(false);
    	          }}
    	          type="update"
    	          title="Update Permission"
    	        />
    	)}
        {id && role === USER_TYPES.ADMIN && <SocketAdminStatus />}
        {id && role === USER_TYPES.CUSTOMER && <SocketCustomerStatus />}
      </Collapse>
      {id && [USER_TYPES.ADMIN, USER_TYPES.SUPER_ADMIN].includes(role) && (
        <Dropdown
          isOpen={dropdownOpen}
          toggle={toggle}
          className="d-none d-md-block"
        >
          <DropdownToggle color="transparent">
            <img
              src={user1}
              alt="profile"
              className="rounded-circle"
              width="30"
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={userLogout}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      {id && [USER_TYPES.CUSTOMER].includes(role) && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle color="transparent">
            <img
              src={user1}
              alt="profile"
              className="rounded-circle"
              width="30"
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={userLogout}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      {id && [USER_TYPES.EMPLOYEE].includes(role) && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle color="transparent">
            <img
              src={user1}
              alt="profile"
              className="rounded-circle"
              width="30"
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={employeeLogout}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      )}
      
    </Navbar>
  );
};

export default Header;
