import { Field, Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
  Input,
} from "reactstrap";
import PageHeader from "../../components/common/PageHeader";
import * as Yup from "yup";
import { api, onlyNumber } from "../../util";
import { toast } from "react-toastify";
import "bootstrap";

const UpsertUser = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [allPermissions , setAllPermissions] = useState([]);
    const [initialValues, setInitialValues] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        code: ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [userPermission , setUserPermission] = useState([]);
  
    useEffect(() => {
        setInitialValues({ ...initialValues, });
    }, []);
 
    const onSubmit = async (values) => {
        if (id === "add") {
            
            if(!values.password){
                toast.error("Please enter password");
                return false;
            }
            setIsLoading(true)
            try {
                const response = await api.post("/users", {
                    ...values,
                    userPermission,
                });
                if (response.status === 201) {
                    toast.success(response?.data?.message || response.message);
                    navigate("/users");
                }
            } catch (error) {
                toast.error(error.response?.data?.message || error.message);
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(true)
            try {
                const response = await api.put("/users/" + id, {
                    ...values,
                    userPermission,
                });
                if (response.status === 200) {
                    toast.success(response?.data?.message || response.message);
                    navigate("/users");
                }
            } catch (error) {
                toast.error(error.response?.data?.message || error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const getUser = async (id) => {
        setIsLoading(true);
        try {
            const response = await api.get("/users/" + id);
            if (response.status === 200) {
                setInitialValues({
                    ...response.data.data,
                    first_name: response.data.data.firstName || "",
                    last_name: response.data.data.lastName || "",
                    email: response.data.data.email || "",
                    code: response.data.data.code || "",
                });
                setUserPermission(response.data.data.permissions.map(value => value?.id));
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getAllPermissions = async () => {
        setIsLoading(true);
        try {
            const response = await api.get("/users/allPermissions");
            if (response.status === 200) {
                setAllPermissions(response.data.data);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        } finally {
            setIsLoading(false);
        }
    }
    
    useEffect(() => {
        getAllPermissions();
        if (id !== "add") {
            getUser(id);
        }
    }, [id]);

    const manageUserPermission = (e) => {
        const permissionValue = e.target?.value;
        if(e.target?.checked) {
            setUserPermission(userPermission => {
                return [...userPermission , permissionValue]
            });
        } else {
            var permission = userPermission.filter((item) => parseInt(item) !== parseInt(permissionValue));
            setUserPermission(permission);
        }
    }
    const [permissionArray , setPermissionArray] = useState([]);

    const getPermission = (name) => {
        switch (name) {
            case 'dashboard' : 
                return 'Dashboard';
            case 'service' : 
                return 'Service';
            case 'saloon' : 
                return 'Salon';
            case 'billing' : 
                return 'Billing';
            case 'userfacing' : 
                return 'User Facing';
            case 'customers-check-in' : 
                return "Customer's Check - In";
            case 'account' : 
                return 'Square Account';
            case 'project' : 
                return 'Square Application';
            case 'employee' : 
                return 'Employee';
            case 'location' : 
                return 'Location';
            case 'time-sheet' : 
                return 'Time Sheet';
            case 'employee-schedule' : 
                return 'Employee Schedule';
            case 'payroll-report' : 
                return 'Payroll Report';
            case 'commission-detailed-report' : 
                return 'Commission Detailed Report';
            case 'tips-detailed-report' : 
                return 'Tips Detailed Report';
            case 'hourly-detailed-report' : 
                return 'Hourly Detailed Report';
            case 'schedule-detailed-report' : 
                return 'Hourly Detailed Report';
            case 'sales-summary' : 
                return 'Hourly Detailed Report';
            case 'today-late-clock-out' : 
                return 'Hourly Detailed Report';
            case 'sms-setting' : 
                return 'Hourly Detailed Report';
        }
    }

    useEffect(() => {
        var permissionArr = [];
        allPermissions.map((permission) => (
            permissionArr.push({id : permission?.id , label : permission?.displayName })
        ))
        setPermissionArray(permissionArr);
    } , [allPermissions]);
 
    return (
        <React.Fragment>
            <Formik
                validateOnChange
                initialValues={{ ...initialValues }}
                validationSchema={
                    Yup.object().shape({
                        first_name: Yup.string().required("Please enter first name"),
                        last_name: Yup.string().required("Please enter last name"),
                        email: Yup.string().required("Please enter email").matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ , 'Please enter valid email'),
                    }) 
                }
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({ errors, values, touched, setValues }) => (
                <>
                    <PageHeader shouldShowAddBtn={false} title={id === "add" ? "Add User" : "Edit User"}
                    />
                    <Card>
                        <CardTitle tag="h5" className="border-bottom p-3 mb-0">
                            <i className="bi bi-person-bounding-box me-2"> </i>
                            {id === "add" ? "Create a new user" : "Edit this user"}
                        </CardTitle>
                        <CardBody>
                            <Form>
                                <Row>
                                    <Col xl={6}>
                                        <FormGroup>
                                            <Label for="name">First Name</Label>
                                            <Field
                                                id="first-name"
                                                name="first_name"
                                                placeholder="First Name"
                                                type="text"
                                                className={
                                                    "form-control" +
                                                    (errors.first_name && touched.first_name ? " is-invalid" : "")
                                                }
                                            />
                                            {errors.first_name && touched.first_name && (
                                            <span component="div" className="invalid-feedback">
                                                {errors.first_name}
                                            </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6}>
                                        <FormGroup>
                                            <Label for="name">Last Name</Label>
                                            <Field
                                                id="last-name"
                                                name="last_name"
                                                placeholder="Last Name"
                                                type="text"
                                                className={
                                                    "form-control" +
                                                    (errors.last_name && touched.last_name ? " is-invalid" : "")
                                                }
                                            />
                                            {errors.last_name && touched.last_name && (
                                            <span component="div" className="invalid-feedback">
                                                {errors.last_name}
                                            </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6}>
                                        <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Field
                                            id="email"
                                            name="email"
                                            placeholder="Email"
                                            type="text"
                                            className={
                                                "form-control" +
                                                (errors.email && touched.email ? " is-invalid" : "")
                                            }
                                        />
                                        {errors.email && touched.email && (
                                            <span component="div" className="invalid-feedback">
                                            {errors.email}
                                            </span>
                                        )}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6}>
                                        <FormGroup>
                                        <Label for="password">Password</Label>
                                        <Field
                                            id="password"
                                            name="password"
                                            placeholder="Password"
                                            autoComplete="new-password"
                                            type="password"
                                            className={
                                                "form-control" +
                                                (errors.password && touched.password ? " is-invalid" : "")
                                            }
                                        />
                                        {errors.password && touched.password && (
                                            <span component="div" className="invalid-feedback">
                                            {errors.password}
                                            </span>
                                        )}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={6}>
                                        <FormGroup>
                                            <Label for="name">Code</Label>
                                            <Field
                                                id="code"
                                                name="code"
                                                placeholder="Code"
                                                type="text"
                                                maxLength={6}
                                                onChange={(e) => onlyNumber(e, values, setValues)}
                                                className={
                                                    "form-control" +
                                                    (errors.code && touched.code ? " is-invalid" : "")
                                                }
                                            />
                                            {errors.code && touched.code && (
                                            <span component="div" className="invalid-feedback">
                                                {errors.code}
                                            </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col xl={12}>
                                        <h4>Permissions : </h4>
                                        <Row>
                                        {permissionArray.map((permission) => (
                                        	<Col xl={4}>
	                                        	<FormGroup check inline className="d-grid gap-2">
	                                                <Input type="checkbox" name={"permission_" + permission?.id} id={"permission-" + permission?.id} className="btn-check" value={permission?.id} checked={userPermission.find(value => value == permission?.id)} onChange={manageUserPermission}/>
	                                                <Label for={"permission-" + permission?.id} className="btn btn-outline-secondary ">{permission?.label}</Label>
	                                            </FormGroup>
                                            </Col>
                                        ))}
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="card-footer border-top-0 d-flex gap-2 mt-4 justify-content-end">
                                    <Button
                                        color="transaprent"
                                        className="border"
                                        type="button"
                                        onClick={() => navigate("/users")}
                                    >
                                    Cancel
                                    </Button>
                                    <Button type="submit" disabled={isLoading} className="btn btn-primary">
                                        {id === "add" ? "Add User" : "Update User"}
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </>
                )}
            </Formik>
        </React.Fragment>
    );
};

export default UpsertUser;
