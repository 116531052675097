import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Input, Row, Col, Label } from "reactstrap";
import DataTable from "../../components/common/DataTable";
import PageHeader from "../../components/common/PageHeader";
import { api, isActionPermitted } from "../../util";
import { getUsersRequest } from "../../redux/actions";
import { toast } from "react-toastify";
import ConfirmModal from "../../components/common/ConfirmModal";

const ListUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { users, count, loading } = useSelector((state) => state.users);
  const { permissions } = useSelector((state) => state.user);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    page: 1,
    limit: 10,
    filter: {
      name: "",
      onlyAdmin:true,
      from: null, // moment().startOf("day").toISOString(),
	    to: null, //moment().endOf("day").toISOString(),
    },
  });

  useEffect(() => {
    if (isActionPermitted("account", "read", permissions)) {
      dispatch(getUsersRequest(paginationConfig));
    }
  }, [paginationConfig, permissions]);

  const onSearchChange = ({ target }) => {
    const { value } = target;
    const pc = paginationConfig;
    pc.filter.name = value;
    setPaginationConfig({ ...pc,  page: 1, limit: 10 });
  };

  const deleteUser = async (id) => {
    try {
      const response = await api.delete("/users/" + id);
      if (response.status === 200) {
        if (isActionPermitted("account", "read", permissions)) {
          dispatch(getUsersRequest(paginationConfig));
        }
        toast.success(response?.data?.message || response.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
    }
  };

  // const changeEmployeeStatus = async (employeeId) => {
  //   // var currentEnableDisableStatus = !e.target.checked;
  //   const statusInfo = {
  //     id: employeeId,
  //     status: currentStatus
  //   }
  //   try {
  //     const response = await api.post('/employees/updateRecordStatus' , statusInfo);

  //     if(response.status === 200) {
  //       if (isActionPermitted("employee", "read", permissions)) {
  //         dispatch(getEmployeesRequest(paginationConfig));
  //         //dispatch(getEmployeesListRequest(paginationConfig));
  //         dispatch(getSaloonsRequest());
  //       }
  //       toast.success(response?.data?.message || response.message);
  //     }
  //   } catch (error) {
  //     toast.error(error.response?.data?.message || error.message);
  //   }
  // }
  return (
    <React.Fragment>
      <PageHeader title="Users" addBtnUrl="/users/add" />
      <Card>
        <CardBody>
          <Row className="gy-3 gx-3">
            <Col xl={3} md={3} sm={12} xs={12}>
              <Label>User</Label>
              <Input
                value={paginationConfig.filter.name}
                type="search"
                placeholder="Search User"
                role="search"
                aria-roledescription="search for user"
                autoFocus
                onChange={onSearchChange}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <DataTable
        isPagination
        count={count}
        loading={loading}
        paginationConfig={paginationConfig}
        setPaginationConfig={setPaginationConfig}
        data={users}
        columns={[
          { 
            Header: "Name",
            Cell : ({row}) => {
              return `${(row?.original?.firstName ? row.original.firstName : '')} ${row?.original?.lastName ? row.original.lastName : ''}`;
            }
          },
          { Header: "Email", accessor: "email"  },
          { Header: "Code", accessor: "code" },
          {
            Header: "Actions",
            accessor: "actions",
            width: "10%",
            Cell: ({ row }) => {
              return (
                <div className="d-flex gap-2">
                  {isActionPermitted("account", "edit", permissions) && (
                    <Button
                      onClick={() => navigate(`/users/${row.original.id}`)}
                    >
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  )}
                  {isActionPermitted("account", "delete", permissions) && (
                    <Button
                      color="danger"
                      onClick={() => {
                        setDeleteId(row.original.id);
                        setShowDeleteModal(true);
                      }}
                    >
                      <i className="bi bi-trash"></i>
                    </Button>
                  )}
                </div>
              );
            },
          },
        ]}
      />

      {showDeleteModal && (
        <ConfirmModal
          title="Delete User"
          message="Are you sure you want to delete this user ?"
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          onOk={() => deleteUser(deleteId)}
          onCancel={() => {
            setDeleteId(null);
            setShowDeleteModal(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default ListUser;
