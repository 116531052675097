import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Button,
	  Card, 
	  CardBody, 
	  CardTitle, 
	  Modal,
	  ModalBody,
	  ModalFooter,
	  ModalHeader,
	  Table } from "reactstrap";
import { useRef } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { getDefaultRoute, USER_TYPES,
	api,
	socket,
	SOCKET,
	SHOW_CLOCK_IN_OUT_PERMISSION_ID,
	USER_ROLES,
	DB_FORMAT,
	WORKING_LOG_EVENT
} from "../util";

const AuthenticatedLayout = ({ children , showSidebar = true , topAlignHeader = null }) => {
  const sidebarRef = useRef(null)
  const hideMobileMenu = () => {
    document.getElementById("sidebarArea").classList.remove("showSidebar");
  };
  
  const user = useSelector((state) => state.user);
  const [showClockInOutModal , setShowClockInOutModal] = useState(false);
  const [employeeWorkingLog , setEmployeeWorkingLog] = useState([]);
  const [employeeName , setEmployeeName] = useState("");
  const [eventName , setEventName] = useState("");
  const [workingLogId , setWorkingLogId] = useState(null);
  const [workingLogPopupStatus , setWorkingLogPopupStatus] = useState(false);
  const { permissions } = useSelector((state) => state.user);
  var miPopup = false;
  
  useEffect(() => {
      if (!socket) return;
        socket.emit(SOCKET.EMIT_JOIN, {
          roomId: "SUPER_ADMIN",
          from: {
            id : user?.id,
            accountId : user?.accountId,
            role : (user?.accountId && user.accountId > 0 ? USER_ROLES.ACCOUNT : USER_ROLES.SUPER_ADMIN)
          }
        });

      socket.on("disconnected", (data) => {
        
      });    

      return () => {
        socket.off(SOCKET.EMIT_JOIN);
      };
  }, []);
  
  useEffect(() => {
	  	//console.log("socket" , socket );
	  	//console.log("permissions" , permissions ); 
	  	if (!socket) return;
	    socket.on(SOCKET.RECEIVE_CLOCK_IN_CLOCK_OUT_DATA_CHANGE, (data) => {
	    	const checkEmpHavePermission = permissions.find(permissions => 
	    		(permissions.id === SHOW_CLOCK_IN_OUT_PERMISSION_ID) 
			);
	    	console.log("socket calling");
	    	console.log("miPopup" , miPopup );
	    	console.log("showClockInOutModal" , showClockInOutModal );
	    	console.log(checkEmpHavePermission);
	    	if( checkEmpHavePermission && showClockInOutModal === false ){
	    		var response_action = data?.actionStatus;
	    		showClockInClockOutEntryPopup();
	    	}
	    });
	    return () => {
	      socket.off(SOCKET.RECEIVE_CLOCK_IN_CLOCK_OUT_DATA_CHANGE);
	    };
  }, []);
  
  const showClockInClockOutEntryPopup = async () => {
      try {
          const response = await api.get('/timesheet/getEmployeeClockInClockOutInfo');
          console.log("response" , response );
          if(response.status === 200) {
              if(response?.data?.data) {
            	  const workingLogInfo = response?.data?.data?.workingLogRecord;
            	  console.log("workingLogInfo" , workingLogInfo );
            	  if( workingLogInfo ) {
            		  console.log("workingLogInfo inner" , workingLogInfo );
            		  if( workingLogInfo?.Employee?.firstName ){
                		  setEmployeeName(workingLogInfo.Employee.firstName + ' ' + workingLogInfo.Employee.lastName + ' For ' + workingLogInfo.Saloon.name );
                	  }
            		  var recordEventName = 'Clock IN';
            		  if(workingLogInfo?.endTime){
            			  var recordEventName = 'Clock Out';
            		  }
            		  recordEventName = workingLogInfo?.event;
            		  setEventName(recordEventName);
                	  setEmployeeWorkingLog(response?.data?.data?.workingLogDetails);
                	  setWorkingLogId(response?.data?.data?.workingLogRecord?.id)
                	  console.log("aane karyu true");
                	  miPopup = true;
                	  setShowClockInOutModal(true);
            	  } else {
            		  console.log("sssssssssss");
            		  miPopup = false;
            	  }
             }
          }
      } catch (error) {
          toast.error(error.response?.data?.message || error.message);
      }
  }
  
  const updateClockInOutPopupStatus = async () => {
	  
	  console.log("workingLogId" , workingLogId )
	  try {
          if( workingLogId ) {
        	  const response = await api.post('/timesheet/updateClockInOutPopupStatus',{'workingLogId' : workingLogId });
              if(response.status === 200) {
            	  miPopup = false;
            	  setShowClockInOutModal(false);
    	  		  showClockInClockOutEntryPopup();
            	  
              }
          } else {
        	  miPopup = false;
        	  setShowClockInOutModal(false);
          }
	  } catch (error) {
          toast.error(error.response?.data?.message || error.message);
      }
      
  }
  
  useEffect(() => {
	  showClockInClockOutEntryPopup()
  }, []);

  var viewIndex = 1;
  const diffBetweenTimeIntoJS = (start_time = null , end_time = null) => {
		
      var currentDate = moment().format("YYYY-MM-DD") ;
  	
      start_time = currentDate + ' ' + start_time ;
      
  	if( end_time === null || end_time === "" ){
  		end_time = moment().format(DB_FORMAT.TIME);
  	}
		end_time = currentDate + ' ' + end_time;
		
		var time_diff = '';
		var diff =  Math.abs(new Date(start_time) - new Date(end_time));
		var seconds = 0;
		if( start_time !== "" && start_time !== null && end_time !== "" && end_time !== null  ){

			var timeStart = new Date(start_time);
			var timeEnd = new Date(end_time);

			if(timeStart > timeEnd){
				var start_diff = timeStart - new Date( currentDate + " 23:59");
			   	var end_diff = new Date( currentDate + " 12:00 AM") - timeEnd;

				time_diff = start_diff + end_diff;
				diff = Math.abs(time_diff);
				
				seconds = Math.floor(diff/1000); //ignore any left over units smaller than a second
				seconds = ( parseFloat( seconds )  > 0 ? ( seconds + 60 ) : seconds );
			} else {
				time_diff = timeStart - timeEnd;
			    diff = Math.abs(time_diff);
				
			    seconds = Math.floor(diff/1000); //ignore any left over units smaller than a second
				
			}
		}
		
		var minutes = Math.floor(seconds/60); 
		
		seconds = seconds % 60;
		var hours = Math.floor(minutes/60);
		minutes = minutes % 60;

		var timeDiff = '';
		if( parseFloat(hours)  > 0.00 && parseFloat(minutes) > 0.00 ){
			timeDiff = hours + (  parseFloat(hours) > 1 ? ' hrs ' : ' hr ' ) +   minutes + (  parseFloat(minutes) > 1 ? ' mins' : ' min' );
		} else {
			if( parseFloat(hours)  > 0 ){
				timeDiff = hours + (  parseFloat(hours) > 1 ? ' hrs ' : ' hr ' );
			}
			if( parseFloat(minutes)  >= 0 ){
				timeDiff = minutes + (  parseFloat(minutes) > 1 ? ' mins' : ' min' );
			}
		}
		//console.log("timeDiff" , timeDiff );
		return timeDiff;
  }
  
  return (
    <main>
      {/********header**********/}
      <Header hideMobileMenu={hideMobileMenu} />
      <div className="pageWrapper d-lg-flex">
        {/********Sidebar**********/}
        <aside  ref={sidebarRef} className={"sidebarArea shadow " + ( showSidebar !== true ? 'd-none' : '')  } data-ss={showSidebar} id="sidebarArea">
          <Sidebar sidebarRef={sidebarRef}  />
        </aside>
        {/********Content Area**********/}
        <div className={"contentArea " + ( ( topAlignHeader !== null && topAlignHeader !== "" ) ? topAlignHeader : '' )  } onClick={hideMobileMenu}>
          {/********Middle Content**********/}
          <Container className="p-4" fluid>
            {children}
          </Container>
          <Modal isOpen={showClockInOutModal}  className="clock-summary-modal" size="lg" centered>
          <ModalHeader>
              <h5 className="mb-0 fw-bolder"><span className="text-danger" style={{fontSize: '15px'}}>{ eventName }</span> {employeeName !== "" ? ' - ' + employeeName : ''}</h5>
          </ModalHeader>
          <ModalBody className="p-0">
          <Card className="newdashboard-table shadow-none mb-0">
              <CardBody className="px-2 py-0">
              <Table responsive>
                  <thead>
                  <tr>
                      <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Sr. No.</th>
                      <th className="text-center" style={{minWidth: '180px' ,width: '180px'}}>Start Time</th>
                      <th className="text-center" style={{minWidth: '110px',width: '110px'}}>End Time</th>
                  </tr>
                  </thead>
                  <tbody>
                      {employeeWorkingLog !== null && employeeWorkingLog?.length > 0 ? (
                          employeeWorkingLog.map((workingLogInfo , index) => (
                              <tr>
                                  {workingLogInfo?.event === WORKING_LOG_EVENT.WORK && (
                                      <>
                                          <td className="text-center">{viewIndex++}</td>
                                          <td className="text-center">{(workingLogInfo?.startTime && workingLogInfo.startTime !== null) ? moment( workingLogInfo.startTime , DB_FORMAT.TIME).format("hh:mm A")  : ' - '}</td>
                                          <td className="text-center">{(workingLogInfo?.endTime && workingLogInfo.endTime !== null) ? moment( workingLogInfo.endTime , DB_FORMAT.TIME).format("hh:mm A") : ' - '}</td>
                                      </>                                        
                                  )}
                                  {workingLogInfo?.event === WORKING_LOG_EVENT.BREAK && (
                                      <>
                                          <td colSpan={3} className="text-center" ><span className="fw-bolder">Break Time: </span>
                                          	{ ( ( ( workingLogInfo?.startTime )  && ( workingLogInfo.startTime !== null ) )  ? diffBetweenTimeIntoJS( workingLogInfo.startTime , workingLogInfo?.endTime )   : '00:00:00' )}
                                          </td>
                                      </>
                                  )}
                              </tr>
                          ))
                          // {emplo}
                      ) : (
                          <>
                              <td colSpan={3} className="text-center" ><span className="fw-bolder">No Data Found</span></td>
                          </>
                      )}
                  </tbody>
              </Table>
              </CardBody>
          </Card>
          </ModalBody>
          <ModalFooter>
              <Button color="dark" className="btn-sm m-0" onClick={updateClockInOutPopupStatus}>Close</Button>
          </ModalFooter>
      </Modal>
        </div>
      </div>
    </main>
  );
};

export default AuthenticatedLayout;
