import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardBody, CardTitle, 
	Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table
} from "reactstrap";
import {
	  api,
	  BILLING_DATA_KEY,
	  PAYMENT_STATUS,
	  roundOff,
	  socket,
	  SOCKET,
	  SOCKET_PING_PONG_INTERVAL,
	  USER_TYPES,
	  QUEUE_STATUS,
	  EMPLOYEE_WORKING_STATUS,
	  WORKING_LOG_EVENT,
	  STATUS,
	  SELECTION,
	  DB_FORMAT,
	  LOCAL_STORAGE_USER,
	  USER_ROLES,
	  SHOW_CLOCK_IN_OUT_PERMISSION_ID
	} from "../../../util";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";

const PageHeader = ({
  title = "",
  shouldShowAddBtn = true,
  shouldShowBackBtn = false,
  addBtnUrl = "",
  backBtnUrl = "",
  addBtnText = <i className="bi bi-plus-circle"></i>,
  onAddBtnClick = () => {},
  onBackBtnClick = () => {},
}) => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardBody className="d-flex gap-2 text-align-center justify-content-between">
        <CardTitle tag="h4" className="mb-0 lh-base">
          {title}
        </CardTitle>

        <div>
        	{shouldShowBackBtn && (
            <Button
              style={{marginLeft: '7px'}}
              onClick={backBtnUrl ? () => navigate(backBtnUrl) : onBackBtnClick}
            >
              Back
            </Button>
          )}
          {shouldShowAddBtn && (
            <Button
              onClick={addBtnUrl ? () => navigate(addBtnUrl) : onAddBtnClick}
            >
              {addBtnText}
            </Button>
          )}
          
        </div>
      </CardBody>
    </Card>
  );
};

export default PageHeader;
