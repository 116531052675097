export const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const BACK_SOCKET_URL = process.env.REACT_APP_BACK_SOCKET_URL;
export const FRONT_BASE_URL = process.env.REACT_APP_FRONT_BASE_URL;

export const LOCAL_STORAGE_USER = "saloon-management-local-storage-user";
export const BILLING_DATA_KEY = "saloon-billing-data";

/**
 * 1000 * 60 * 2 = 2 minutes
 * The time interval on which we'll send ping pong socket events
 */
export const SOCKET_PING_PONG_INTERVAL = 1000 * 60 * 2;

/**
 * 1000 * 60 * 3 = 3 minutes
 * The time limit, when timestamp from socket is older than this timeout limit
 * we declare connection inactive
 */
export const SOCKET_PING_PONG_TIMEOUT = 1000 * 60 * 3;

/**
 * 1000 * 60 * 1 = 1 minute | 
 * The time interval we'll check if socket timestamp is older then current time 
 */
export const SOCKET_PING_PONG_INTERVAL_CHECK_TIMESTAMP = 1000 * 60 * 1;


export const EMAIL_REGEX =
  /^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/;

export const CATEGORIES = [
  "Eyebrow Threading",
  "Eyelash",
  "Hair Service",
  "Henna",
  "Other Threading",
  "Products",
  "Services",
  "Skincare",
  "Tinting",
  "Waxing",
  "Other",
];

// ROLES
export const USER_TYPES = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  CUSTOMER: 3,
  BOOK_CUSTOMER: 4,
  EMPLOYEE: 5,
};

export const SOCKET = {
  CONNECT: "connect",

  EMIT_JOIN: "EMIT_JOIN",
  EMIT_BILLING_DATA_CHANGE: "EMIT_BILLING_DATA_CHANGE",
  EMIT_BILLING_FINALIZE: "EMIT_BILLING_FINALIZE",

  EMIT_USER_DATA_CHANGE: "EMIT_USER_DATA_CHANGE",
  LISTEN_USER_DATA_CHANGE: "LISTEN_USER_DATA_CHANGE",

  LISTEN_BILLING_FINALIZE: "LISTEN_BILLING_FINALIZE",
  RECEIVE_BILLING_DATA_CHANGE: "RECEIVE_BILLING_DATA_CHANGE",

  LISTEN_DEVICE_PAIRED_CHANGE: "LISTEN_DEVICE_PAIRED_CHANGE",

  LISTEN_PAYMENT_STATUS_CHANGE: "LISTEN_PAYMENT_STATUS_CHANGE",
  EMIT_QUEUE_DATA_CHANGE: "EMIT_QUEUE_DATA_CHANGE",
  RECEIVE_QUEUE_DATA_CHANGE: "RECEIVE_QUEUE_DATA_CHANGE",
  
  EMIT_CLOCK_IN_CLOCK_OUT_DATA_CHANGE: "EMIT_CLOCK_IN_CLOCK_OUT_DATA_CHANGE",
  RECEIVE_CLOCK_IN_CLOCK_OUT_DATA_CHANGE: "RECEIVE_CLOCK_IN_CLOCK_OUT_DATA_CHANGE",
};

export const PAYMENT_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  ADDING_SERVICES: "ADDING_SERVICES",
  FINALIZED: "FINALIZED",
  PAYMENT_TYPE_CASH: "PAYMENT_TYPE_CASH",
  PAYMENT_TYPE_CARD: "PAYMENT_TYPE_CARD",
  PAYMENT_TYPE_CONFIRM: "PAYMENT_TYPE_CONFIRM",
  PAYMENT_TYPE_SELECTED: "PAYMENT_TYPE_SELECTED",

  SQUARE_PAYMENT_STARTED: "SQUARE_PAYMENT_STARTED",
  PAYMENT_DONE: "PAYMENT_DONE",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
  REQUEST_CANCEL_SQUARE_PAYMENT: "REQUEST_CANCEL_SQUARE_PAYMENT",
  CANCELED: "CANCELED",
};
export const BILLING = {
  userData: {},
  checkoutList: [],
  paymentType: "",

  isPaymentComplete: false,
  isPaymentStarted: false,
  paymentStatus: PAYMENT_STATUS.NOT_STARTED,
};

export const SQUARE_PAYMENT_STATUS = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  CANCEL_REQUESTED: "CANCEL_REQUESTED",
  CANCELED: "CANCELED",
  COMPLETED: "COMPLETED",
};

export const FIXED_VALUE_INCENTIVE = "Fixed";
export const PRECENTAGE_VALUE_INCENTIVE = "Percentage";

export const SELECTION = {
  YES : "Yes",
  NO : "No"
}

export const QUEUE_STATUS = {
  COMPLETED: "Completed",
  UPCOMING: "Upcoming",
  ONGOING: "Ongoing",
  CANCELED: "Canceled",
}

export const EMPLOYEE_WORKING_STATUS = {
  CLOCK_IN: "CLOCK_IN",
  BREAK: "BREAK",
  BREAK_OUT:"BREAK_OUT",
  CLOCK_OUT: "CLOCK_OUT",
}

export const LATE_STATUS = {
  PENDING : 'Pending',
  APPROVED : 'Approved',
  REJECTED : 'Rejected',
}

export const STATUS = {
  ACTIVE : 1,
  INACTIVE : 0,
  ACTIVE_TEXT : "Active",
  INACTIVE_TEXT : 'Inactive',
}

export const WORKING_LOG_EVENT = {
  WORK: "WORK",
  BREAK: "BREAK",
}

export const DB_FORMAT = {
  DATE : "YYYY-MM-DD",
  TIME : "HH:mm:ss",
  DATE_TIME : "YYYY-MM-DD HH:mm:ss"
}

export const WORKING_LOG_TIME = {
  START_TIME : "START_TIME",
  END_TIME : 'END_TIME'
}

export const USER_ROLES = {
  ACCOUNT : 'ACCOUNT',
  SUPER_ADMIN : 'SUPER_ADMIN',
  VISITOR : 'VISITOR',
}

export const TIME_ZONE = "America/New_York";
export const TWILIO_AUTH_TOKEN = "d1c15b0b5f4aa56f8149c374d46f65af";
export const TWILIO_ACCOUNT_SID = "ACb5c51823acccb9d370b0b4948932307b";
export const SHOW_CLOCK_IN_OUT_PERMISSION_ID = 21;
export const SHOW_NO_SALES_PERMISSION_ID = 22;
export const SUPER_ADMIN_LOGIN_ID = 25;