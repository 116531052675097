import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Field , Form , Formik } from "formik";
import {
    Button,
    Col,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Label,
    CardBody,
    CardTitle,
    Card,
    Table,
} from "reactstrap";
import * as Yup from "yup";
import {
    api,
    DB_FORMAT,
    EMPLOYEE_WORKING_STATUS,
    onlyNumber,
    WORKING_LOG_EVENT,
    socket, 
    SOCKET, 
    USER_ROLES,
    USER_TYPES
} from "../../util";
import { toast } from "react-toastify";
import moment from "moment";
import 'moment-timezone';

const EmployeeTimeSheet = () => {
	const user = useSelector((state) => state.user);
    const [employeesList, setEmployeesList] = useState([]);
    const [renderRequired , setRenderRequired] = useState(true);
    const [showLoginCodeModal, setShowLoginCodeModal] = useState(false);
    const toggle = () => setShowLoginCodeModal(!showLoginCodeModal);
    const [employeeId , setEmployeeId] = useState(null);
    const [employeeStatus , setEmployeeStatus] = useState(null);
    const [showAddRemarkModal , setShowAddRemarkModal] = useState(false);
    const toggleRemarkModal = () => setShowAddRemarkModal(!showAddRemarkModal);
    const [isLoading, setIsLoading] = useState(false);

    const formik1Ref = useRef();
    const formik2Ref = useRef();

    const fetchEmployees = async () => {
        try {
          const response = await api.get("/timesheet/getScheduledEmployees");
          const employees = response?.data?.data?.list;
          setEmployeesList(employees);
        } catch (error) {
          toast.error(error.response?.data?.message || error.message);
        }
    };

    useEffect(() => {
        if(renderRequired) {
            setRenderRequired(false);
            fetchEmployees();
        }
    }, [renderRequired]);

    const checkLoginCode = async (loginCode) => {
        const data = {};
        data.login_code = loginCode;
        data.employee_id = employeeId;
        setIsLoading(true)
        setAddButtonDisabled(true);
        try {
            const response = await api.post( '/timesheet/checkLoginCode' , data );
            //console.log('response',response)
            if(response.status === 200) {
                if(response?.data?.code === 200) {
                    if(employeeStatus === EMPLOYEE_WORKING_STATUS.CLOCK_OUT) {
                        await checkClockOutTime();
                    } else {
                        await changeEmployeeStatus();
                    }
                } else {
                	setAddButtonDisabled(false);
                    toast.error(response?.data?.message || "Invalid Login Code");
                }
            } else {
            	//console.log('sssss');
            	setAddButtonDisabled(false);
            }
        } catch (error) {
        	setAddButtonDisabled(false);
            toast.error(error.response?.data?.message || error.message);
        } finally {
	        setIsLoading(false);
	        setAddButtonDisabled(false);
        }
    }
   
    const changeEmployeeStatus = async (lateRemark = null) => {

        const data = {};
        data.id = employeeId;
        data.status = employeeStatus;
        if(lateRemark !== null) {
            data.late_remark = lateRemark;
        }
        setIsLoading(true)
        try {
            const response = await api.post('timesheet/changeEmployeeStatus' , data);
            if(response.status === 200) {
                if(response?.data?.code === 200) {
                    toast.success(response?.data?.message || "Status changed successfully");
                    var actionStatus = response?.data?.data?.status;
                    setRenderRequired(true);
                    setShowAddRemarkModal(false);
                    setShowLoginCodeModal(false);
                    if( ( actionStatus === EMPLOYEE_WORKING_STATUS.CLOCK_OUT  ) ||  (  actionStatus === EMPLOYEE_WORKING_STATUS.CLOCK_IN )  ){
                    	console.log("int sockeet");
                    	socket.emit(SOCKET.EMIT_CLOCK_IN_CLOCK_OUT_DATA_CHANGE, {'actionStatus' : actionStatus });
                    }
                } else {
                    toast.error(response?.data?.message || "Status was not changed");
                }
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        } finally {
	        setIsLoading(false);
	    }
    }

    const openLoginCodeModal = (id , status) => {
        formik1Ref.current?.resetForm();
        formik2Ref.current?.resetForm();
        setEmployeeId(id);
        setEmployeeStatus(status);
        setShowLoginCodeModal(true);
        setAddButtonDisabled(false);
    }

    const checkClockOutTime = async () => {
        const data = {};
        data.id = employeeId;
        data.status = EMPLOYEE_WORKING_STATUS.CLOCK_OUT;
        data.is_api_request = true;
        try {
            const response = await api.post('timesheet/checkEmployeeTime' , data);
            if(response.status === 200) {
                if(response?.data?.code === 101) {
                    openAddRemarkModal()
                    //setAddButtonDisabled(false);
                } else if (response?.data?.code === 200) {
                    await changeEmployeeStatus();
                    //setAddButtonDisabled(false);
                }
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
    }

    const openAddRemarkModal = () => {
        setShowAddRemarkModal(true);
    }

    const [showViewModal , setShowViewModal] = useState(false);
    const toggleViewModal = () => setShowViewModal(!showViewModal);
    const [employeeWorkingLog , setEmployeeWorkingLog] = useState([]);
    const [employeeName , setEmployeeName] = useState("");
    // const [totalWorkingTime , setTotalWorkingTime] = useState(0);
    // const [totalBreakTime , setTotalBreakTime] = useState(0);
    var viewIndex = 1;

    const openViewModal = async (data) => {
        try {
            const response = await api.post('/timesheet/getEmployeeWorkingLog' , { employee_id : data?.id });
            if(response.status === 200) {
                setEmployeeName(data?.firstName + ' ' + data?.lastName);
                setEmployeeWorkingLog(response?.data?.data?.workingLog);
                setShowViewModal(true);
                
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        }
    }
    // useEffect(() => {
    //     if(employeeWorkingLog !== null && employeeWorkingLog?.length > 0) {
    //         var workingTime = 0;
    //         var breakTime = 0;
    //         employeeWorkingLog.forEach((workingLogInfo , index) => {
                
    //             var startTime = moment(workingLogInfo?.startTime , DB_FORMAT.TIME);
    //             var endTime = moment((workingLogInfo?.endTime && workingLogInfo.endTime !== null ? workingLogInfo.endTime : moment().format(DB_FORMAT.TIME)) , DB_FORMAT.TIME);

    //             var timeDifference = moment(endTime).diff(startTime , 'utc');
    //             if(workingLogInfo?.event === WORKING_LOG_EVENT.WORK) {
    //                 workingTime += parseInt(timeDifference);
    //             }
    //             if(workingLogInfo?.event === WORKING_LOG_EVENT.BREAK) {
    //                 breakTime += parseInt(timeDifference);
    //             }
    //         })
    //         setTotalWorkingTime(moment.utc(workingTime).format(DB_FORMAT.TIME));
    //         setTotalBreakTime(moment.utc(breakTime).format(DB_FORMAT.TIME));
    //     }
    // } , [employeeWorkingLog])
    
    const diffBetweenTimeIntoJS = (start_time = null , end_time = null) => {
		
        var currentDate = moment().format("YYYY-MM-DD") ;
    	
        start_time = currentDate + ' ' + start_time ;
        
    	if( end_time === null || end_time === "" ){
    		end_time = moment().format(DB_FORMAT.TIME);
    	}
		end_time = currentDate + ' ' + end_time;
		
		var time_diff = '';
		var diff =  Math.abs(new Date(start_time) - new Date(end_time));
		var seconds = 0;
		if( start_time !== "" && start_time !== null && end_time !== "" && end_time !== null  ){

			var timeStart = new Date(start_time);
			var timeEnd = new Date(end_time);

			if(timeStart > timeEnd){
				var start_diff = timeStart - new Date( currentDate + " 23:59");
			   	var end_diff = new Date( currentDate + " 12:00 AM") - timeEnd;

				time_diff = start_diff + end_diff;
				diff = Math.abs(time_diff);
				
				seconds = Math.floor(diff/1000); //ignore any left over units smaller than a second
				seconds = ( parseFloat( seconds )  > 0 ? ( seconds + 60 ) : seconds );
			} else {
				time_diff = timeStart - timeEnd;
			    diff = Math.abs(time_diff);
				
			    seconds = Math.floor(diff/1000); //ignore any left over units smaller than a second
				
			}
		}
		
		var minutes = Math.floor(seconds/60); 
		
		seconds = seconds % 60;
		var hours = Math.floor(minutes/60);
		minutes = minutes % 60;

		var timeDiff = '';
		if( parseFloat(hours)  > 0.00 && parseFloat(minutes) > 0.00 ){
			timeDiff = hours + (  parseFloat(hours) > 1 ? ' hrs ' : ' hr ' ) +   minutes + (  parseFloat(minutes) > 1 ? ' mins' : ' min' );
		} else {
			if( parseFloat(hours)  > 0 ){
				timeDiff = hours + (  parseFloat(hours) > 1 ? ' hrs ' : ' hr ' );
			}
			if( parseFloat(minutes)  >= 0 ){
				timeDiff = minutes + (  parseFloat(minutes) > 1 ? ' mins' : ' min' );
			}
		}
		//console.log("timeDiff" , timeDiff );
		return timeDiff;
	}
    const [addButtonDisabled , setAddButtonDisabled] = useState(false);
    
    useEffect(() => {
		if (!socket) return;
			socket.emit(SOCKET.EMIT_JOIN, {
		      roomId: user?.saloon?.roomId,
		      from: {
		        id: user.id,
		        roleNumber: user.role,
		        name: user.firstName,
		        role: user.role === USER_TYPES.ADMIN ? "ADMIN" : "CUSTOMER",
		      },
		    });
		return () => {
		  socket.off(SOCKET.EMIT_JOIN);
		};
	}, []);
    return (
        <React.Fragment>
            <Formik
                validateOnChange
                initialValues={
                    {login_code : ""}
                }
                validationSchema={
                    Yup.object().shape({
                        login_code: Yup.number().required("Please enter login code"),
                    })
                }
                onSubmit={(e) => {
                    //console.log('form 1 submit')
                    
                    checkLoginCode(e?.login_code);
                }}
                enableReinitialize
                innerRef={formik1Ref}
            >
                {({ errors , values , touched , setValues , submitForm}) => (
                    <>
                    <Form>
                        <Modal size="md" centered isOpen={showLoginCodeModal} toggle={toggle}>
                            <ModalHeader toggle={toggle} className="text-capitalize">
                                Login Code
                            </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="login_code">
                                                Enter Login Code
                                            </Label>
                                            <Field
                                                onChange={(e) => onlyNumber(e , values , setValues)}
                                                id="login_code"
                                                name="login_code"
                                                placeholder="Login Code"
                                                type="text"
                                                maxLength={4}
                                                // invalid={touched.loginCode && errors.loginCode}
                                                autoComplete="off"
                                                className={
                                                    "form-control" +
                                                    (errors.login_code && touched.login_code ? " is-invalid" : "")
                                                }
                                            />
                                            {errors.login_code && touched.login_code && (
                                                <span component="div" className="invalid-feedback">
                                                {errors.login_code}
                                                </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter tag={"div"} className="transparant border-0 bg-light bg-light">
                                <Button
                                    type="button"
                                    onClick={toggle}
                                    color="transprent"
                                    className="border"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color={"primary"}
                                	disabled={ addButtonDisabled == true ? 'disabled' : ''}
                                    className="text-capitalize"
                                    onClick={submitForm}
                                >
                                    Add
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Form>
                    </>
                )}
            </Formik>            
            <Formik 
                validateOnChange
                initialValues={
                    {late_remark : ""}
                }
                validationSchema={
                    Yup.object().shape({
                        late_remark: Yup.string().required("Please enter remark"),
                    })
                }
                onSubmit={(e) => {
                    changeEmployeeStatus(e?.late_remark);
                }}
                enableReinitialize
                innerRef={formik2Ref}
            >
                {({ errors , values , touched , submitForm}) => (
                    <>
                    <Form>
                        <Modal size="md" centered isOpen={showAddRemarkModal} toggle={toggleRemarkModal}>
                            <ModalHeader toggle={toggleRemarkModal} className="text-capitalize">
                                Remark
                            </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="late_remark">
                                                Enter Remark
                                            </Label>
                                            <Field
                                                // onChange={formik2.handleChange}
                                                // {...formik2.getFieldProps("lateRemark")}
                                                id="late_remark"
                                                name="late_remark"
                                                placeholder="Remark"
                                                type="text"
                                                autoComplete="off"
                                                className={
                                                    "form-control" +
                                                    (touched.late_remark && errors.late_remark ? " is-invalid" : "")
                                                }
                                            />
                                            {errors.late_remark && touched.late_remark && (
                                                <span component="div" className="invalid-feedback">
                                                    {errors.late_remark}
                                                </span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter tag={"div"} className="transparant border-0 bg-light bg-light">
                                <Button
                                    type="button"
                                    onClick={toggleRemarkModal}
                                    color="transprent"
                                    className="border"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    color={"primary"}
                                    className="text-capitalize"
                                    onClick={submitForm}
                                >
                                    Add
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Form>
                    </>
                )}
            </Formik>
            
            <Row>
                {(employeesList?.length > 0) ? employeesList.map((value , index) => (
                        <Col lg={4}>
                            <Card>
                                <CardBody className="d-flex flex-column align-items-center gap-2 text-align-center justify-content-between">
                                    <CardTitle tag="h4" className="mb-0 lh-base ts-emp-name" onClick={() => openViewModal(value?.Employee)}>
                                        {value?.Employee?.firstName + ' ' + value?.Employee?.lastName}
                                    </CardTitle>
                                    {value?.Employee?.is_pause === 0 && value?.Employee?.is_start === 0 && (
                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={() => openLoginCodeModal(value?.Employee?.id , EMPLOYEE_WORKING_STATUS.CLOCK_IN)}><i class="bi bi-play-circle"></i> Clock In</button>
                                        </div>
                                    )} 
                                    {value?.Employee?.is_pause === 0 && value?.Employee?.is_start === 1 && (
                                        <div>
                                            <button type="button" className="me-2 btn btn-outline-secondary" onClick={() => openLoginCodeModal(value?.Employee?.id , EMPLOYEE_WORKING_STATUS.BREAK)}><i class="bi bi-pause-circle"></i> Break</button>
                                            <button type="button" className="btn btn-secondary" onClick={() => openLoginCodeModal(value?.Employee?.id , EMPLOYEE_WORKING_STATUS.CLOCK_OUT)}><i class="bi bi-stop-circle"></i> Clock Out</button>
                                        </div>
                                    )}
                                    {value?.Employee?.is_pause === 1 && value?.Employee?.is_start === 1 && (
                                        <div>
                                            <button type="button" className="me-2 btn btn-danger" onClick={() => openLoginCodeModal(value?.Employee?.id , EMPLOYEE_WORKING_STATUS.BREAK_OUT)}><i class="bi bi-arrow-return-right"></i> Return From Break</button>
                                            <button type="button" className="btn btn-secondary" onClick={() => openLoginCodeModal(value?.Employee?.id , EMPLOYEE_WORKING_STATUS.CLOCK_OUT)}><i class="bi bi-stop-circle"></i> Clock Out</button>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                )) : <></> }
            </Row>
            <Modal isOpen={showViewModal} toggle={toggleViewModal} className="clock-summary-modal" size="lg" centered>
                <ModalHeader closeButton>
                    <h5 className="mb-0 fw-bolder">Day Summary{employeeName !== "" ? ' - ' + employeeName : ''}</h5>
                </ModalHeader>
                <ModalBody className="p-0">
                <Card className="newdashboard-table shadow-none mb-0">
                    <CardBody className="px-2 py-0">
                    <Table responsive>
                        <thead>
                        <tr>
                            <th className="text-center" style={{minWidth: '110px',width: '110px'}}>Sr. No.</th>
                            <th className="text-center" style={{minWidth: '180px' ,width: '180px'}}>Start Time</th>
                            <th className="text-center" style={{minWidth: '110px',width: '110px'}}>End Time</th>
                        </tr>
                        </thead>
                        <tbody>
                            {employeeWorkingLog !== null && employeeWorkingLog?.length > 0 ? (
                                employeeWorkingLog.map((workingLogInfo , index) => (
                                    <tr>
                                        {workingLogInfo?.event === WORKING_LOG_EVENT.WORK && (
                                            <>
                                                <td className="text-center">{viewIndex++}</td>
                                                <td className="text-center">{(workingLogInfo?.startTime && workingLogInfo.startTime !== null) ? moment( workingLogInfo.startTime , DB_FORMAT.TIME).format("hh:mm A")  : ' - '}</td>
                                                <td className="text-center">{(workingLogInfo?.endTime && workingLogInfo.endTime !== null) ? moment( workingLogInfo.endTime , DB_FORMAT.TIME).format("hh:mm A") : ' - '}</td>
                                            </>                                        
                                        )}
                                        {workingLogInfo?.event === WORKING_LOG_EVENT.BREAK && (
                                            <>
                                                <td colSpan={3} className="text-center" ><span className="fw-bolder">Break Time: </span>
                                                	{ ( ( ( workingLogInfo?.startTime )  && ( workingLogInfo.startTime !== null ) )  ? diffBetweenTimeIntoJS( workingLogInfo.startTime , workingLogInfo?.endTime )   : '00:00:00' )}
                                                </td>
                                            </>
                                        )}
                                    </tr>
                                ))
                                // {emplo}
                            ) : (
                                <>
                                    <td colSpan={3} className="text-center" ><span className="fw-bolder">No Data Found</span></td>
                                </>
                            )}
                        </tbody>
                    </Table>
                    </CardBody>
                </Card>
                </ModalBody>
                <ModalFooter>
                    <Button color="dark" className="btn-sm m-0" onClick={toggleViewModal}>Close</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default EmployeeTimeSheet;